<template>
  <div>
    <!-- Add -->
    <v-dialog width="1220" persistent v-model="dialog" scrollable>
      <v-card>
        <v-card-title style="font-size: 25px; height: 75px" class="pb-4">
          <v-icon style="color: #363636" class="mr-1"
            >mdi-plus-box-outline</v-icon
          >
          {{ $t("newlessonlearn") }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="cancelAdd()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" lg="12">
                <p style="color: #424242; font-size: 16px">
                  {{ $t("createdateM") }}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {{ this.createdate }} {{ $t("By") }} {{ this.fullName }}
                </p>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <label for="title" class="font-style">
                      {{ $t("title") }} <span style="color: red">*</span>
                    </label>
                    <v-text-field
                      :rules="titleRules"
                      outlined
                      dense
                      hide-details="auto"
                      id="title"
                      v-model="lessonlearn.caseTitle"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label for="description" class="font-style">{{
                      $t("description")
                    }}</label>
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      id="description"
                      v-model="lessonlearn.caseDescription"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <label for="startDate" class="font-style">{{
                      $t("startdate")
                    }}</label>
                    <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="rounded-lg"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          id="startDate"
                          prepend-inner-icon="mdi-calendar-blank-outline"
                          v-model="lessonlearn.startDate"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedStartDates"
                        color="secondary"
                        v-model="lessonlearn.startDate"
                        @input="startdateDis()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <label for="endDate" class="font-style">{{
                      $t("enddate")
                    }}</label>
                    <v-menu
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="rounded-lg"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          id="endDate"
                          prepend-inner-icon="mdi-calendar-blank-outline"
                          v-model="lessonlearn.endDate"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedEndDates"
                        color="secondary"
                        v-model="lessonlearn.endDate"
                        @input="endDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-progress-circular
                v-if="loading"
                indeterminate
                class="loading-circle"
                color="#A6CC39"
              ></v-progress-circular>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="7">
                    <p
                      class="text-left font-style"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>
                    <div class="drop">
                      <v-card
                        width="290"
                        height="215"
                        style="
                          border: 2px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.file.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropImg($event)"
                      >
                        <v-img
                          :src="lessonlearn.file"
                          @click="$refs.file.click()"
                          @mouseover="
                            lessonlearn.file
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            lessonlearn.file
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="240"
                          height="167"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                          v-if="lessonlearn.file"
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="previewimg = true"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="error"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="lessonlearn.file = null"
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>

                        <v-img
                          v-if="!lessonlearn.file"
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.file.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="file"
                          accept="image/*"
                          @change="uploadImg($event, 1)"
                        />
                        <p
                          v-show="!lessonlearn.file"
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-show="!lessonlearn.file"
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.file.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p
                        class="mt-3 pr-3"
                        style="color: #777777; font-size: 12px"
                      >
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="12">
                <label class="font-style" for="wordContent"
                  >{{ $t("wordcontent") }}
                  <span style="color: red">*</span></label
                >
                <v-textarea
                  :rules="wordContentRules"
                  outlined
                  no-resize
                  id="wordContent"
                  v-model="lessonlearn.wordContent"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <label for="link" class="font-style">{{ $t("link") }}</label>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  id="link"
                  @input="checkLinkType"
                  v-model="lessonlearn.link"
                ></v-text-field>
                <span v-if="notLink" style="color: red; font-size: 13px">{{
                  $t("invalidUrlLink")
                }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <input
                  type="file"
                  accept="application/pdf"
                  class="d-none"
                  ref="pdffile"
                  @change="getpdfFile($event)"
                />
                <v-btn
                  :disabled="lessonlearn.PDFButtonDisable"
                  color="#A6CC39"
                  @click="($refs.pdffile.value = null), $refs.pdffile.click()"
                  style="font-size: 13px; border-radius: 8px"
                  class="text-capitalize white--text reset-btn"
                >
                  <v-icon class="mr-2" style="font-size: 18px"
                    >mdi-folder-search-outline</v-icon
                  >
                  {{ $t("Browse File") }}
                </v-btn>

                <label
                  for="browsefiles"
                  style="margin-left: 10px"
                  v-if="lessonlearn.pdffilename != ''"
                  class="font-style"
                  >{{ lessonlearn.pdffilename }}</label
                >
                <v-icon
                  @click="DeletePDFfile"
                  width="20"
                  height="20"
                  color="error"
                  v-if="lessonlearn.PDFButtonDisable"
                  style="cursor: pointer; border: 1px"
                  >mdi-delete</v-icon
                >
                <p class="mt-3 pr-3" style="color: #777777; font-size: 12px">
                  {{ $t("RecommendFormatPDF") }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox
                  color="#a6cc39"
                  v-model="allview"
                  :label="$t('allusercanview')"
                ></v-checkbox>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  color="#a6cc39"
                  style="
                    font-size: 16px;
                    margin-top: 13px;
                    float: right;
                    border-radius: 8px;
                  "
                  class="text-capitalize white--text"
                  @click="AddParticipant"
                  >{{ $t("requesteduser") }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="selectedList.length != 0">
                <v-data-table
                  :items="selectedList"
                  class="lessonlearn-table"
                  :loading="userLoading"
                  loading-text="Loading... Please wait"
                  :headers="selectedListUserHeader"
                  hide-default-footer
                  @page-count="pageCount = $event"
                  :page.sync="page"
                  :no-data-text="$t('nodata')"
                >
                  <template v-slot:header.index="{ header }">{{
                    header.text
                  }}</template>
                  <template v-slot:item.request="{ item }">
                    <v-row justify="center">
                      <v-checkbox
                        color="#A6CC39"
                        v-model="item.request"
                      ></v-checkbox>
                    </v-row>
                  </template>
                  <template v-slot:item.date="{ item }">
                    <div v-if="item.request" style="width: 230px">
                      <v-menu
                        ref="menu"
                        v-model="item.menu"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :close-on-content-click="false"
                        :return-value.sync="item.date"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :rules="[rulesDate.required]"
                            v-on="on"
                            class="date-time"
                            v-bind="attrs"
                            v-model="item.dateStatus"
                            prepend-inner-icon="mdi-calendar-blank-outline"
                            outlined
                            dense
                            readonly
                            hide-details="auto"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :allowed-dates="allowedDatesPerDay"
                          v-model="item.perDay"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            class="font-style"
                            @click="item.menu = false"
                            >{{ $t("cancel") }}</v-btn
                          >
                          <v-btn
                            text
                            class="font-style"
                            color="primary"
                            @click="
                              $refs.menu.save(item.perDay),
                                (item.menu = false),
                                (item.dateStatus = checkStatus(item.perDay))
                            "
                            >{{ $t("ok") }}</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </template>
                  <template v-slot:item.index="{ item }">
                    <v-btn
                      color="error"
                      text
                      style="font-size: 16px; color: #fa5252 !important"
                      class="text-capitalize"
                      @click="DeletefromUser(item)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                      {{ $t("remove") }}
                    </v-btn>
                  </template>
                  <template v-slot:no-data>
                    <p style="font-size: 16px">{{ $t("nodata") }}</p>
                  </template>
                </v-data-table>
                <v-row justify="end">
                  <div class="ma-5 d-flex">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-card class="customcardnoti">
                <v-card-subtitle
                  style="color: #424242; font-size: 18px; font-weight: bold"
                >
                  {{ $t("sendnotifications") }}
                </v-card-subtitle>
              </v-card>
            </v-row>
            <v-row class="ml-6 mr-12 mt-7" rows="12" sm="12" md="12" lg="12">
              <div class="ml-5" >
                <v-card width="205px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: start;
                        "
                        >
                <v-checkbox
                  class="pb-5 ml-3"
                  :label="$t('inbox')"
                  color="#a6cc39"
                  v-model="inbox_flag"
                  hide-details
                ></v-checkbox
              ></v-card>
            </div>
              <div class="ml-10"
                >
                <v-card width="205px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: start;
                        "
                        >
                <v-checkbox
                class="pb-5 ml-3"
                  color="#a6cc39"
                  :label="$t('pushnotification')"
                  v-model="pushNoti_flag"
                  hide-details
                ></v-checkbox
              ></v-card>
          </div>
              
              <div class="ml-10" >
                <v-card width="205px"
                        height="35px"
                        style="
                          border: 1px dashed #c0c0c0;
                          box-shadow: unset;
                          border-radius: 4px;
                          display: flex;
                          align-items: center;
                          justify-content: start;
                        "
                        >
                <v-checkbox
                 class="pb-5 ml-3"
                  color="#a6cc39"
                  :label="$t('line')"
                  v-model="line_flag"
                  hide-details
                ></v-checkbox>
              </v-card>
            </div>
              <div
              class="d-flex justify-start ml-10"
              >
                <v-card
                  width="300px"
                  height="35px"
                  style="
                    border: 1.5px dashed #c0c0c0;
                    box-shadow: unset;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                  "
                >
                <v-row
                    class="d-flex justify-start align-center"
                    no-gutters
                  >
                    <v-col class="d-flex justify-start align-center" cols="5">
                      <v-checkbox
                        class="pb-5 pl-3"
                        color="#a6cc39"
                        :label="$t('email')"
                        v-model="email_flag"
                        @change="handleCheckboxChange"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <template>
                      <v-col class="d-flex justify-start align-center">
                        <v-row class="d-flex justify-start align-center ml-3">
                          <v-radio-group v-model="selectedRadio" class="radio-group" :disable="!email_flag">
                            <v-row rows="12" class="radiobutton">
                              <div class="radio-wrapper">
                             
                             <div v-if="!email_flag" class="radio-placeholder"></div>
                            
                             <v-radio
                               v-else
                               value="thai"
                               class="radio-button"
                             ></v-radio>
                           </div>
                           <v-img
                              src="@/assets/thailandflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                            <div class="radio-wrapper ml-2">
                             
                             <div v-if="!email_flag" class="radio-placeholder"> </div>
                            <v-radio
                            v-else
                            class=" radio-button"
                             value="eng"
                            
                              >
                            </v-radio>
                            </div>
                            <v-img
                              src="@/assets/ukflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                            </v-row>
                            
                          </v-radio-group>
                         
                        </v-row>
                      </v-col>
                    </template>
                    
                  </v-row>
                </v-card>
              </div>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            text
            outlined
            @click="cancelAdd()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style"
            color="secondary"
            @click="submit()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- preview image -->
    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius: 8px; margin: auto"
          :src="lessonlearn.file"
        ></v-img>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCrop"
      persistent
      width="1000px"
      height="800px"
      overflow="hidden"
    >
      <v-card class="rounded-lg" overflow="hidden">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 40px"
          >Crop Image</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            outlined
            text
            @click="CancelCrop()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize font-style reset-btn"
            color="secondary"
            width="80px"
            @click="CropImage()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Participant list -->
    <v-dialog width="500" v-model="listDialog" scrollable persistent>
      <v-card>
        <v-card-title>
          <v-tabs hide-slider v-model="tabs" class="pt-3">
            <v-tab
              href="#tab-1"
              class="font-style text-capitalize"
              style="
                width: 135px;
                border-radius: 10px 0px 0px 10px;
                border: 1px solid #9fc437;
              "
              >{{ $t("user") }}</v-tab
            >
            <v-tab
              href="#tab-2"
              style="
                width: 135px;
                border-radius: 0px 10px 10px 0px;
                border: 1px solid #9fc437;
              "
              class="font-style text-capitalize"
              >{{ $t("departmentl") }}</v-tab
            >
          </v-tabs>
        </v-card-title>
        <v-card-text style="height: 500px">
          <v-tabs-items v-model="tabs" class="mt-5">
            <v-tab-item value="tab-1">
              <v-data-table
                :items="users"
                item-key="userID"
                :loading="userLoading"
                @toggle-select-all="SelectAllUser"
                loading-text="Loading... Please wait"
                v-model="userSelected"
                show-select
                :headers="userListHeader"
                hide-default-footer
                @page-count="userpageCount = $event"
                :page.sync="userpage"
                :no-data-text="$t('nodata')"
              ></v-data-table>
              <v-row no-gutters class="mt-1">
                <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10" class="p-0">
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="userpage"
                      class="pagination"
                      :length="userpageCount"
                    ></v-pagination>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-data-table
                :items="departments"
                @toggle-select-all="SelectAllDept"
                item-key="groupID"
                :loading="departmentLoading"
                loading-text="Loading... Please wait"
                v-model="departmentSelected"
                show-select
                :headers="departmentListHeader"
                hide-default-footer
                @page-count="deptpageCount = $event"
                :page.sync="deptpage"
                :no-data-text="$t('nodata')"
              ></v-data-table>
              <v-row no-gutters class="mt-1">
                <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10" class="p-0">
                  <div class="d-flex text-left">
                    <v-pagination
                      circle
                      v-model="deptpage"
                      class="pagination"
                      :length="deptpageCount"
                    ></v-pagination>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            text
            outlined
            @click="
              (listDialog = false),
                (userSelected = []),
                ((departmentSelected = []), (this.tabs = 'tab-1'))
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize font-style"
            color="secondary"
            @click="savePermissionList()"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import * as moment from "moment/moment";

export default {
  components: {
    VueCropper,
  },
  props: {
    show: {
      type: Boolean,
      default: null,
    },
    fullName: String,
  },
  computed: {
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    userListHeader() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          value: "fullName",
          // width: "500px",
        },
      ];
    },
    departmentListHeader() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          value: "groupName",
        },
      ];
    },
    selectedListUserHeader() {
      return [
        {
          text: "#",
          align: "center",
        },
        {
          text: this.$t("name"),
          align: "left",
          value: "fullName",
          width: "500px",
        },
        // {
        //   text: this.$t("viewpermission"),
        //   align: "center",
        //   value: "viewPermission"
        // },
        {
          text: this.$t("request"),
          align: "center",
          value: "request",
          width: "165px",
        },
        {
          text: this.$t("expiredate"),
          align: "left",
          value: "date",
        },
        // {
        //   text: this.$t("sendnotification"),
        //   sortable: false,
        //   value: "noti",
        // },
        {
          text: "Action",
          align: "center",
          value: "index",
        },
      ];
    },
    selectedListDepartmentHeader() {
      return [
        {
          text: "#",
          align: "center",
        },
        {
          text: this.$t("name"),
          align: "center",
          value: "groupName",
        },
        {
          text: this.$t("viewpermission"),
          align: "center",
          value: "viewPermission",
        },
        {
          text: this.$t("assign"),
          align: "center",
          value: "request",
        },
        // {
        //   text: this.$t("repeat"),
        //   align: "center",
        //   value: "repeat"
        // },
        {
          text: this.$t("expiredate"),
          align: "center",
          value: "date",
        },
        {
          text: this.$t("action"),
          align: "center",
          value: "index",
        },
      ];
    },
    titleRules() {
      return [(v) => !!v || "Title is required"];
    },
    wordContentRules() {
      return [(v) => !!v || "Word Content is required"];
    },
    isLink() {
      // Regular expression to check if the input is a valid URL
      // const urlRegex = /^(ftp|http(s)?:\/\/)?(www\.)[^ "]+$/;
      // return this.lessonlearn.link.length != 0
      //   ? urlRegex.test(this.lessonlearn.link)
      //   : true;
      const urlRegex = /^(https?:\/\/(www\.)|(www\.))|(ftp|http(s)?:\/\/).+$/;
      let result;
      if (this.lessonlearn.link != null) {
        if (this.lessonlearn.link.length != 0) {
          result = urlRegex.test(this.lessonlearn.link);
        } else {
          result = true;
        }
      } else {
        result = true;
      }

      return result;
    },
  },
  data: () => ({
    selectedRadio: null,
    inbox_flag: false,
    pushNoti_flag: false,
    email_flag: false,
    line_flag: false,
    notLink: false,
    showEditImgTool: false,
    createdate: moment(new Date().toISOString().substr(0, 10))
      .local()
      .format("DD MMM YYYY"),
    loading: false,
    rules: {
      required: (value) => !!value || "",
    },
    rulesDate: {
      required: (v) => v.split("-").length > 2 || "",
    },
    selectedList: [],
    showSelectedList: false,
    dialogCrop: false,
    page: 1,
    pageCount: 0,
    userpage: 1,
    userpageCount: 0,
    deptpage: 1,
    deptpageCount: 0,
    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: false,
      autoCrop: true,
      autoCropWidth: 800,
      autoCropHeight: 450,
      centerBox: false,
      high: true,
    },
    valid: true,
    lessonlearn: {
      caseTitle: "",
      caseDescription: "",
      wordContent: "",
      caseImage: "",
      link: "",
      companyId: localStorage.getItem("companyID"),
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      file: null,
      fileName: "",
      fileUrl: "",
      pdffilename: "",
      PDFUrl: "",
      PDFButtonDisable: false,
    },
    isUser: false,
    fileError: false,
    startDateMenu: false,
    endDateMenu: false,
    listDialog: false,
    userSelected: [],
    departmentSelected: [],
    tabs: "tab-1",
    users: [],
    departments: [],
    userLoading: true,
    departmentLoading: true,
    perList: [],
    userdataList: [],
    departmentdataList: [],
    allview: true,
    previewimg: false,
    // sendnotification: [
    //   {
    //     id: 1,
    //     name: "Inbox",
    //   },
    //   {
    //     id: 2,
    //     name: "Push Notification",
    //   },
    //   {
    //     id: 3,
    //     name: "Email",
    //   },
    // ],
  }),
  mounted() {
    this.getUserData();
    this.getDepartment();
    console.log("Case");
  },
  watch: {
    userSelected(val) {
      if (val.length != 0) {
        this.departmentSelected = [];
        this.isUser = true;
        console.log(val);
        console.log(this.userSelected);
      }
    },
    departmentSelected(val) {
      if (val.length != 0) {
        this.userSelected = [];
        this.isUser = false;
      }
    },
  },
  methods: {
    handleCheckboxChange() {
      if (this.email_flag) {
        // When checkbox is checked, set the default radio button value
        this.selectedRadio = 'thai';
      } else {
        // When checkbox is unchecked, clear the selected radio button
        this.selectedRadio = null;
      }
    },
    checkLinkType() {
      if (this.isLink || this.lessonlearn.link === "") {
        this.notLink = false;
      } else {
        this.notLink = true;
      }
    },
    startdateDis() {
      let that = this;
      that.startDateMenu = false;
      if (that.lessonlearn.startDate > that.lessonlearn.endDate) {
        that.lessonlearn.endDate = that.lessonlearn.startDate;
      }
    },
    AddParticipant() {
      this.listDialog = true;
      if (this.isUser) {
        this.userSelected = this.perList;
      } else {
        this.departmentSelected = this.perList;
      }
    },
    DeletefromUser(i) {
      this.selectedList.splice(
        this.selectedList.findIndex((item) => item.index === i.index),
        1
      );
      if (this.perList.length != 0) {
        this.perList.splice(
          this.perList.findIndex((item) => item.index === i.index),
          1
        );
      }
    },
    // getSelectAll(v) {
    //   if (v.noti.length == 0) {
    //     var course = this.sendnotification.map(({ id }) => id);
    //     this.selectedList[v.index].noti = course;
    //   } else {
    //     this.course = [];
    //   }
    // },
    async savePermissionList() {
      let that = this;
      that.listDialog = false;
      let user = [];
      let department = [];
      if (that.isUser) {
        for (let i = 0; i < that.userSelected.length; i++) {
          const filteruserdata = that.userdataList.find(
            (c) => c.employeeID == that.userSelected[i].userID
          );
          if (filteruserdata == undefined) {
            user.push({
              employeeID: that.userSelected[i].userID,
              fullName: that.userSelected[i].fullName,
              isCheck: true,
              isUser: true,
              perDay: that.userSelected[i].perDay,
              // permission: false,
              request: false,
              dateStatus: "Please Select",
              times: 1,
              userPermissionID: that.userSelected[i].userID,
              index: i,
              // noti: [],
            });
          } else {
            user.push({
              employeeID: filteruserdata.employeeID,
              fullName: filteruserdata.fullName,
              isCheck: filteruserdata.isCheck,
              isUser: filteruserdata.isUser,
              perDay: filteruserdata.perDay,
              // permission: filteruserdata.permission,
              request: filteruserdata.request,
              dateStatus: filteruserdata.dateStatus,
              times: filteruserdata.times,
              userPermissionID: filteruserdata.userPermissionID,
              index: i,
              // noti: [],
            });
          }
        }
      } else {
        for (let i = 0; i < that.departmentSelected.length; i++) {
          const filterdepartdata = that.departmentdataList.find(
            (c) => c.employeeID == that.departmentSelected[i].groupID
          );
          if (filterdepartdata == undefined) {
            department.push({
              employeeID: that.departmentSelected[i].groupID,
              fullName: that.departmentSelected[i].groupName,
              isCheck: true,
              isUser: false,
              perDay: that.departmentSelected[i].perDay,
              // permission: false,
              request: false,
              dateStatus: "Date Picker",
              times: 1,
              userPermissionID: that.departmentSelected[i].groupID,
              index: i,
              // noti: [],
            });
          } else {
            department.push({
              employeeID: filterdepartdata.groupID,
              fullName: filterdepartdata.fullName,
              isCheck: filterdepartdata.isCheck,
              isUser: filterdepartdata.isUser,
              perDay: filterdepartdata.perDay,
              // permission: filterdepartdata.permission,
              request: filterdepartdata.request,
              dateStatus: filterdepartdata.dateStatus,
              times: filterdepartdata.times,
              userPermissionID: filterdepartdata.groupID,
              index: i,
              // noti: [],
            });
          }
        }
      }
      that.selectedList = that.isUser ? user : department;
      that.userdataList = user;
      that.departmentdataList = department;
      that.perList = that.isUser ? that.userSelected : that.departmentSelected;
    },

    dropImg(e) {
      let file = e.dataTransfer.files[0];
      if (!/(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(file.name)) {
        alert("Please choose image!");
        return false;
      }
      this.lessonlearn.fileName = file.name;
      let reader = new FileReader();
      reader.onload = (f) => {
        // f.target.result contains the base64 encoding of the image
        let src;
        if (typeof f.target.result === "object") {
          src = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          src = f.target.result;
        }
        this.option.img = src;
        this.dialogCrop = true;
      };
      reader.readAsArrayBuffer(file);
    },

    uploadImg(e, num) {
      let self = this;
      let file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      this.lessonlearn.fileName = file.name;
      let reader = new FileReader();
      reader.onload = function (f) {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
    CancelCrop() {
      this.dialogCrop = false;
    },
    CropImage() {
      this.$refs.cropper.getCropData((data) => {
        this.getFile(data);
        this.dialogCrop = false;
      });
    },
    cancelAdd() {
      this.$emit("close");
      this.$refs.file.value = null;
      this.$refs.form.resetValidation();
      this.fileError = false;
      this.lessonlearn = {
        caseTitle: "",
        caseDescription: "",
        startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        wordContent: "",
        file: null,
        fileName: "",
        fileUrl: "",
        link: "",
        pdffilename: "",
        PDFUrl: "",
        PDFButtonDisable: false,
      };
      this.departmentSelected = [];
      this.userSelected = [];
      this.selectedList = [];
      this.perList = [];
      this.userdataList = [];
      this.departmentdataList = [];
      this.allview = true;
      this.inbox_flag = false;
      this.pushNoti_flag = false;
      this.email_flag = false;
      this.line_flag = false;
    },
    SelectAllUser() {
      if (this.userSelected == undefined) {
        this.userSelected = this.users;
      } else if (this.userSelected.length == 0) {
        this.userSelected = this.users;
      } else {
        this.userSelected = [];
      }
    },
    SelectAllDept() {
      if (this.departmentSelected == undefined) {
        this.departmentSelected = this.departments;
      } else if (this.departmentSelected.length == 0) {
        this.departmentSelected = this.departments;
      } else {
        this.departmentSelected = [];
      }
    },
    getFile(e) {
      this.lessonlearn.fileUrl = e;
      this.lessonlearn.file = e;
      this.showEditImgTool = false;
    },
    async getpdfFile(e) {
      let that = this;
      let file = e.target.files[0];
      if (!/\.pdf$/.test(e.target.value)) {
        alert("Choose PDF files only, please re-select!");
        return false;
      } else {
        that.lessonlearn.pdffilename = file.name;
        that.lessonlearn.PDFUrl = e;
        that.lessonlearn.PDFButtonDisable = true;
      }
    },
    DeletePDFfile() {
      let that = this;
      that.lessonlearn.pdffilename = "";
      that.lessonlearn.PDFUrl = "";
      that.lessonlearn.PDFButtonDisable = false;
    },
    imageTobase64(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.lessonlearn.file = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    allowedStartDates: (val) => val >= new Date().toISOString().substr(0, 10),
    allowedEndDates(val) {
      return val >= this.lessonlearn.startDate;
    },
    allowedDatesPerDay(val) {
      let sDate = new Date(this.lessonlearn.startDate);
      let eDate = new Date(this.lessonlearn.endDate);
      if (new Date(val) >= sDate && new Date(val) <= eDate) return val;
    },
    checkStatus(item) {
      if (item) {
        return item;
      } else {
        return "Date Picker";
      }
    },
    async getUserData() {
      this.userLoading = true;
      const res = await this.$axios.get(
        `${this.web_urlV6}EmployeeEXT/GetEmployeeExtAllV4?id=` +
          localStorage.getItem("companyID")
      );
      this.users = res.data.data.map((v, i) => ({
        ...v,
        // permission: false,
        request: false,
        repeat: 0,
        menu: false,
        date: [],
        dateStatus: "Date Picker",
        index: i + 1,
      }));
      this.userLoading = false;
    },
    async getDepartment() {
      this.departmentLoading = true;
      const res = await this.$axios.get(
        `${this.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
      this.departments = res.data.data.map((v, i) => ({
        ...v,
        // permission: false,
        request: false,
        repeat: 0,
        menu: false,
        date: [],
        dateStatus: "Date Picker",
        index: i,
      }));
      this.departmentLoading = false;
    },
    async submit() {
      if (this.$refs.form.validate() && this.notLink === false) {
        if (this.lessonlearn.file) {
          this.loading = true;
          let permissionList = [];
          for (const element of this.selectedList) {
            permissionList.push({
              employeeID: element.employeeID,
              fullName: element.fullName,
              isCheck: element.isCheck,
              isUser: element.isUser,
              perDay: element.perDay,
              //  permission: this.selectedList[i].permission,
              request: element.request,
              selectedDate: element.dateStatus,
              times: 1, //parseInt(this.selectedList[i].repeat),
              userPermissionID: element.userPermissionID,
            });
          }
          if (this.lessonlearn.PDFUrl != "") {
            let formData = new FormData();
            formData.append("file", this.lessonlearn.PDFUrl.target.files[0]);
            const res = await this.$axios.post(
              `${this.web_url}File/UploadPDFFile`,
              formData
            );
            this.lessonlearn.PDFUrl = res.data.data;
          }
          const base64Res = await this.$axios.post(
            `${this.web_url}File/UploadFileStringBase`,
            {
              base64: this.lessonlearn.file,
              fileName: this.lessonlearn.fileName,
            }
          );

          const userdata = JSON.parse(
            localStorage.getItem("vivek_authenticated_user_data")
          );
          const memberid = userdata.memberId;
          const data = {
            permissionData: permissionList,
            endContentDate: this.lessonlearn.endDate,
            isUserGroup: this.isUser ? 2 : 1,
            caseImage: base64Res.data,
            companyId: localStorage.getItem("companyID"),
            startContentDate: this.lessonlearn.startDate,
            // topicID: this.editLibraryData.id,
            caseTitle: this.lessonlearn.caseTitle,
            wordContent: this.lessonlearn.wordContent,
            caseDescription: this.lessonlearn.caseDescription,
            imgBase64: this.lessonlearn.file,
            allView: this.allview == null ? true : this.allview,
            createBy: memberid,
            inboxFlag: this.inbox_flag,
            emailFlag: this.email_flag,
            emailLanguage: this.selectedRadio,
            pushNotiFlag: this.pushNoti_flag,
            lineFlag: this.line_flag,
            link: this.lessonlearn.link,
            pdFfilename: this.lessonlearn.pdffilename,
            pdfUrl: this.lessonlearn.PDFUrl,
            // inboxFlag: true, // this.lessonlearn.inbox,
            // emailFlag: false, // this.lessonlearn.email,
            // pushNotiFlag: true, // this.lessonlearn.pushnoti,
          };
          console.log("emaildata",data);
          await this.$axios.post(
            `${this.web_url}CaseStudies/AddCaseStudy`,
            data
          );

          this.lessonlearn = {
            caseTitle: "",
            caseDescription: "",
            startDate: new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10),
            endDate: new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10),
            wordContent: "",
            file: null,
            fileName: "",
            fileUrl: "",
            pdffilename: "",
            PDFUrl: "",
          };
          this.inbox_flag = false;
          this.pushNoti_flag = false;
          this.email_flag = false;
          this.line_flag = false;
          this.$refs.file.value = null;
          this.$emit("confirm");
          this.cancelAdd();
          this.fileError = false;
        } else {
          this.fileError = true;
          alert("Please attach a picture!");
        }
      } else {
        alert("Incomplete information Please enter again!");
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.radio-group {
  display: flex;
  align-items: center;
}
.radiobutton{
  margin-top: -10px;
}
.radio-wrapper {
  position: relative;
  width: 22px;
  height: 22px;
}

.radio-placeholder,
.radio-button {
  position: absolute;
  top: 2px;
 

left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.radio-placeholder {
  background-color:#424242;
  opacity: 0.1;
  pointer-events: none; /* Prevent interaction */
}
.customcardnoti {
  width: 98%;
  margin-left: 10px;
  background-color: #e0e0e0;
}
::v-deep .v-dialog {
  border-radius: 16px !important;
}
::v-deep .preview_class.v-dialog {
  border-radius: 8px !important;
}
::v-deep .v-text-field__slot {
  font-size: 20px !important;
}
.font-style {
  font-size: 16px;
  color: #424242;
}
.btnfont-style {
  font-size: 16px;
  border-radius: 8px;
}
::v-deep .v-input__slot {
  border-radius: 10px !important;
}
::v-deep .department-table tr:nth-child(even) {
  background: #e0e0e0;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .v-tab--active {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-minus-box {
  color: #a6cc39 !important;
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .theme--light.v-input input,
.theme--light.v-input textarea {
  font-size: 16px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
::v-deep .chk-input .v-input__control {
  align-content: end;
}
::v-deep .chk-input .v-input__slot {
  width: 200px;
}
::v-deep .v-list-item__content {
  color: #4d4f5c;
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0 !important;
}
</style>

<style scoped>
.btn_hover_effect {
  width: 50%;
  color: #fff;
  font-size: 11px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.reset-btn {
  width: 13%;
  color: #fff;
  font-size: 11px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  /* opacity: 1; */
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-minus-box {
  color: #a6cc39 !important;
}
</style>
